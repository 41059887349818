import * as React from "react";
import { useSelector } from "react-redux";
import {
    convertToPercent,
    customValueRenderer,
    dateFilterColumn,
    freeTextColumn,
    multiSelectColumn,
    nonFilterableColumn,
    roundNumbers,
    thousandsSeparatorRound,
} from "../../components/DataTable";
import Form, {
    BooleanField,
    CurrencyField,
    DateField,
    ExchangeField,
    FileField,
    MultipleSecuritiesField,
    SelectField,
    SourceField,
    TextField,
} from "../../components/Form";
import QueryDataTable from "../../components/QueryDataTable";
import { DateTime } from "luxon";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useState } from "react";
import Authentication from "../../modules/authentication";
import { getExchangeName } from "../../store/exchanges";
import { ASSET_TYPE, ISSUE_TYPE, UNDERLYING_ASSET } from "./ManagedSecurities";

const gApiClient = Authentication.getAPIClient();

// Eikon Dividend Type
const EIKON_DIVIDEND_TYPE = {
    approximate_final: "Approximate Final",
    approximate_interim: "Approximate interim",
    extra: "Extra",
    final: "Final",
    forecast_final: "Forecast final",
    forecast_interim: "Forecast interim",
    forecast_special: "Forecast special",
    interim: "Interim",
    special: "Special",
    spacial_final: "Spacial final",
    special_interim: "Special Interim",
    stock_dividend_different_stock: "Stock dividend, different stock",
};

export default function DailyMarketData() {
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const securitiesById = useSelector(
        (state) => state.securities.securitiesById,
    );
    const exchangesById = useSelector((state) => state.exchanges.exchangesById);

    const UploadSourceField = {
        ...SelectField,
        optionsFromSelector: (state) =>
            state.securities.managedSources
                .filter((x) =>
                    ["DSS", "TASEEOD", "TASENextOpen"].includes(x.name),
                )
                .map((e) => ({
                    id: e._id,
                    label: e.name,
                })),
    };

    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now()
                .startOf("day")
                .minus({ days: 1 })
                .toMillis(),
        },
        {
            name: "end_date",
            label: "End Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now()
                .startOf("day")
                .minus({ days: 1 })
                .toMillis(),
        },
        {
            name: "securities",
            label: "Securities",
            type: MultipleSecuritiesField,
            required: false,
        },
        {
            name: "name",
            label: "Name",
            type: TextField,
        },
        {
            name: "ric",
            label: "RIC",
            type: TextField,
        },
        {
            name: "isin",
            label: "ISIN",
            type: TextField,
        },
        {
            name: "source_id",
            label: "Source",
            type: SourceField,
        },
        {
            name: "exchange_id",
            label: "Exchange",
            type: ExchangeField,
        },
        {
            name: "asset_type",
            label: "Asset Type",
            type: SelectField,
            options: Object.keys(ASSET_TYPE).map((k) => ({
                id: k,
                label: ASSET_TYPE[k],
            })),
        },
        {
            name: "issue_type",
            label: "Issue Type",
            type: SelectField,
            options: Object.keys(ISSUE_TYPE).map((k) => ({
                id: k,
                label: ISSUE_TYPE[k],
            })),
        },
        {
            name: "currency",
            label: "Currency",
            type: CurrencyField,
        },
        {
            name: "security_exchange_id",
            label: "Security Exchange ID",
            type: TextField,
        },
        {
            name: "underlying_asset",
            label: "Underlying Asset",
            type: SelectField,
            options: Object.keys(UNDERLYING_ASSET).map((k) => ({
                id: k,
                label: UNDERLYING_ASSET[k],
            })),
        },
    ];

    const TABLE_COLUMNS = [
        dateFilterColumn("date", "Date"),
        freeTextColumn("security_name", "Security Name"),
        freeTextColumn("security_ric", "RIC"),
        freeTextColumn("security_isin", "ISIN"),
        multiSelectColumn("exchange", "Exchange"),
        multiSelectColumn("security_currency", "Currency"),
        nonFilterableColumn("last_close_price", "Last Close Price"),
        nonFilterableColumn("last_close_price_usd", "Last Close Price (USD)"),

        // EIKON related columns
        nonFilterableColumn("eikon_close_price", "Price Close", {
            display: false,
        }),
        nonFilterableColumn("shares_outstanding", "Shares Outstanding", {
            display: false,
            ...thousandsSeparatorRound(0),
        }),
        nonFilterableColumn("volume", "Volume", {
            display: false,
            ...thousandsSeparatorRound(0),
        }),
        nonFilterableColumn("cf_volume", "CF_VOLUME", {
            display: false,
            ...thousandsSeparatorRound(0),
        }),
        nonFilterableColumn(
            "pe_daily_time_series_ratio",
            "P/E (Daily Time Series Ratio)",
            { display: false },
        ),
        nonFilterableColumn(
            "price_to_book_value_per_share",
            "Price To Book Value Per Share (Daily Time Series Ratio)",
            { display: false },
        ),
        nonFilterableColumn(
            "net_income_after_taxes",
            "Net Income After Taxes",
            { display: false, ...thousandsSeparatorRound(0) },
        ),
        nonFilterableColumn("free_float_percent", "Free Float (Percent)", {
            display: false,
            ...roundNumbers(4),
        }),
        dateFilterColumn("eikon_dividend_ex_date", "Dividend Ex Date", {
            display: false,
        }),
        nonFilterableColumn(
            "eikon_adjusted_gross_dividend_amount",
            "Adjusted Gross Dividend Amount",
            { display: false },
        ),
        nonFilterableColumn(
            "eikon_adjusted_net_dividend_amount",
            "Adjusted Net Dividend Amount",
            { display: false },
        ),
        multiSelectColumn("eikon_dividend_currency", "Dividend Currency", {
            display: false,
        }),
        nonFilterableColumn("eikon_adjust_cls", "ADJUST_CLS", {
            display: false,
        }),
        multiSelectColumn("eikon_dividend_type", "Dividend Type", {
            display: false,
            ...customValueRenderer((x) => EIKON_DIVIDEND_TYPE[x]),
        }),
        dateFilterColumn("eikon_dividend_pay_date", "Dividend Pay Date", {
            display: false,
        }),
        nonFilterableColumn(
            "average_daily_value_traded_52_weeks",
            "Average Daily Value Traded - 52 Weeks",
            { display: false },
        ),
        nonFilterableColumn(
            "net_income_after_taxes",
            "Net Income After Taxes",
            { display: false, ...thousandsSeparatorRound(1) },
        ),
        nonFilterableColumn("net_income_actual", "Net Income - Actual", {
            display: false,
            ...thousandsSeparatorRound(1),
        }),

        // Megama related columns
        nonFilterableColumn("listed_capital", "Listed Capital", {
            display: false,
        }),
        nonFilterableColumn("value", "value", { display: false }),
        nonFilterableColumn("volume", "volume", { display: false }),
        nonFilterableColumn("average_volume_90", "Average Volume 90", {
            display: false,
        }),
        nonFilterableColumn("close_volume", "Close Volume", { display: false }),
        nonFilterableColumn("yield_gross", "Yield Gross", {
            display: false,
            ...convertToPercent(3),
        }),
        nonFilterableColumn("net_income", "Net Income", {
            display: false,
            ...thousandsSeparatorRound(1),
        }),
        nonFilterableColumn("duration_gross", "Duration Gross", {
            display: false,
            ...roundNumbers(2),
        }),
        nonFilterableColumn(
            "institute_holdings_pcg",
            "Institute Holdings Pcg",
            { display: false, ...convertToPercent(2) },
        ),
        nonFilterableColumn("public_holdings_pcg", "Public Holdings Pcg", {
            display: false,
            ...convertToPercent(2),
        }),
        multiSelectColumn("maalot_rating_il", "Maalot Rating IL", {
            display: false,
        }),
        multiSelectColumn("midroog_rating_il", "Midroog Rating IL", {
            display: false,
        }),
        nonFilterableColumn("syn_diff", "Syn Diff", {
            display: false,
            ...convertToPercent(2),
        }),
        nonFilterableColumn("pe_ratio_ifrs", "Pe Ratio ifrs", {
            display: false,
            ...roundNumbers(2),
        }),
        nonFilterableColumn("pb_ratio_ifrs", "Pb Ratio ifrs", {
            display: false,
            ...roundNumbers(2),
        }),
        nonFilterableColumn("dividend_yield", "Dividend Yield", {
            display: false,
            ...convertToPercent(2),
        }),
    ];

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "security_name",
            direction: "asc",
        },
    };

    const UPLOAD_MARKET_DATA_FIELDS = [
        {
            name: "date",
            label: "Date",
            type: DateField,
            required: true,
        },
        {
            name: "source_id",
            label: "Source",
            type: UploadSourceField,
            required: true,
        },
        {
            name: "file",
            label: "Daily Market File",
            type: FileField,
            required: true,
        },
        {
            name: "mark_as_complete",
            label: "Mark Import Process as Complete (For the input date)",
            type: BooleanField,
            required: true,
            defaultValue: false,
        },
    ];

    const enrichResults = (results) => {
        return results.map((x) => ({
            ...x,
            security_name: securitiesById[x.security_id].name,
            security_ric: securitiesById[x.security_id].ric,
            security_isin: securitiesById[x.security_id].isin,
            security_currency: securitiesById[x.security_id].currency,
            exchange: getExchangeName(
                exchangesById[securitiesById[x.security_id].exchange_id],
            ),
        }));
    };

    const onSubmit = async (values) => {
        const isXLS =
            values.file.filename.endsWith(".xls") ||
            values.file.filename.endsWith(".xlsx");

        const response = await gApiClient.callApi(
            `import_daily_market_data?date=${values.date}&source_id=${
                values.source_id
            }&file_type=${isXLS ? "xls" : "csv"}&mark_as_complete=${
                values.mark_as_complete ? "1" : "0"
            }`,
            {
                method: "POST",
                body: values.file.fileBody,
            },
        );

        console.log("Response", response.data);

        setShowUploadDialog(false);
    };

    return (
        <>
            <Dialog
                open={showUploadDialog}
                onClose={() => {
                    setShowUploadDialog(false);
                }}
                maxWidth={"md"}
                fullWidth={true}
            >
                <div className="p-5 flex flex-col items-center">
                    <div className="text-center mb-8 text-3xl">
                        Upload Daily Market Data
                    </div>
                    <Form
                        fields={UPLOAD_MARKET_DATA_FIELDS}
                        onSubmit={onSubmit}
                        submitButtonText="Upload"
                        hideResetButton={true}
                        className="flex-col"
                        additionalButtons={[
                            {
                                text: "Cancel",
                                onPress: () => setShowUploadDialog(false),
                            },
                        ]}
                    />
                </div>
            </Dialog>
            <div className="absolute right-10 top-10">
                <Tooltip title={"Upload Daily Market Data"}>
                    <IconButton onClick={() => setShowUploadDialog(true)}>
                        <FileUpload />
                    </IconButton>
                </Tooltip>
            </div>
            <QueryDataTable
                queryFields={QUERY_FIELDS}
                queryAPIPath="daily_market_data"
                tableColumns={TABLE_COLUMNS}
                tableOptions={TABLE_OPTIONS}
                preprocessResults={enrichResults}
                dynamicLoad={true}
            />
        </>
    );
}
